<script setup>
import { defineProps, defineEmits } from "vue";
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  showCancel: {
    type: Boolean,
    default: true,
  },
  showConfirm: {
    type: Boolean,
    default: true,
  },
});
const emits = defineEmits(["update:show", "confirm"]);
const closeModal = () => {
  emits("update:show", false);
};
</script>
<template>
  <transition
    enter-active-class="duration-300 ease-out"
    enter-from-class="opacity-0 -mt-96"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0 -mt-96"
  >
    <div
      v-if="props.show"
      class="
        flex
        justify-center
        h-screen
        w-screen
        items-center
        z-40
        absolute
        top-0
        left-0
        -translate-x-1/2
        rounded-xl
      "
    >
      <div class="h-screen w-screen bg-grey bg-opacity-60 absolute">
      <div
        class="
          flex flex-col
          sm:w-sm
          md:w-md
          lg:w-md
          xl:w-lg
          max-w-6xl
          mx-auto
          rounded-xl
          z-20
          overflow-y-scroll
          h-screen
          mt-2
          mb-2
        "
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white relative rounded-t-xl">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            <slot name="title"></slot>
          </h2>
          <div
            v-if="!showCancel"
            class="right-2 top-2 absolute"
            @click="closeModal"
          >
            <span
              class="
                material-icons-outlined
                bg-red
                rounded-full
                text-white
                cursor-pointer
              "
            >
              close
            </span>
          </div>
        </div>
        <div class="flex flex-col p-4 bg-white">
          <slot name="content"></slot>
        </div>
        <div
          class="
            flex flex-row
            items-center
            justify-end
            p-5
            bg-white
            border-t border-lightGrey
            rounded-bl-lg rounded-br-lg
          "
        >
          <div
            v-if="showCancel"
            @click="closeModal"
            class="
              px-11
              py-2
              text-white
              font-semibold
              bg-orange
              rounded-full
              cursor-pointer
            "
          >
            Cancel
          </div>
          <base-button @click="emits('confirm')" v-if="showConfirm">
            <slot name="successButton"></slot>
          </base-button>
        </div>
      </div>
    </div>
    </div>
  </transition>
</template>
